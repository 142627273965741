import axios from 'axios';
import {
  URL_CREATE_REQUEST,
  URL_CREATE_SUCCESS,
  URL_CREATE_FAIL,
  URL_REDIRECT_REQUEST,
  URL_REDIRECT_SUCCESS,
  URL_REDIRECT_FAIL,
} from '../constants/urlConstants';

export const createUrl = (longUrl) => async (dispatch) => {
  try {
    dispatch({
      type: URL_CREATE_REQUEST,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(
      'https://sh0rten3r.herokuapp.com/api/url/shorten',
      { longUrl },
      config
    );

    dispatch({
      type: URL_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: URL_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const redirect = (code) => async (dispatch) => {
  try {
    dispatch({
      type: URL_REDIRECT_REQUEST,
    });

    await axios.get(`https://sh0rten3r.herokuapp.com/${code}`);

    dispatch({
      type: URL_REDIRECT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: URL_REDIRECT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
