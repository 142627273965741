import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { redirectReducer, urlReducer } from './reducers/urlReducers';

const reducer = combineReducers({
  urls: urlReducer,
  redirect: redirectReducer,
});

const middleware = [thunk];

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
