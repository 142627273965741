import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
} from 'react-bootstrap';
import Loader from '../components/Loader';
import { createUrl } from '../actions/urlActions';
import 'bootstrap/dist/css/bootstrap.min.css';

const HomeScreen = ({ match, history }) => {
  const [url, setUrl] = useState('');

  const urlCode = match.params.code;

  const dispatch = useDispatch();

  const urls = useSelector((state) => state.urls);
  const { loading, error, urls: createdUrl } = urls;

  useEffect(() => {
    if (urlCode) {
      window.location = `https://sh0rten3r.herokuapp.com/${urlCode}`;
    }
  }, [urlCode]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createUrl(url));
  };

  return (
    <Container>
      {urlCode ? (
        <Row style={{ marginTop: '10rem' }}>
          <Col className='text-center '>
            <Loader />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col xs={12}>
              <h1 className='my-4 pb-4 text-center'>hrtn.me | URL shortener</h1>
              <Form onSubmit={submitHandler}>
                <Form.Row className='justify-content-center'>
                  <Col xs='8'>
                    <Form.Label htmlFor='inlineFormInputGroup' srOnly>
                      URL
                    </Form.Label>
                    <InputGroup className='mb-2'>
                      <FormControl
                        id='inlineFormInputGroup'
                        placeholder='URL'
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col xs='auto'>
                    <Button type='submit' className='mb-2'>
                      shorten
                    </Button>
                  </Col>
                </Form.Row>
              </Form>
            </Col>
          </Row>
          <Row className='my-5 justify-content-center form-row'>
            <Col className='text-center '>
              {createdUrl && (
                <a href={createdUrl.shortUrl}>{createdUrl.shortUrl}</a>
              )}
              {error && <p>{error.message}</p>}
            </Col>
          </Row>
          <Row className='my-5'>
            <Col className='text-center '>{loading && <Loader />}</Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default HomeScreen;
