import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import HomeScreen from './screens/HomeScreen';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <Router>
      {/* <Header /> */}
      <main className='py-3'>
        <Container>
          <Route path='/:code?' component={HomeScreen} />
        </Container>
      </main>
      {/* <Footer /> */}
    </Router>
  );
};

export default App;
